import * as Sentry from '@sentry/react';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import type { ReactNode } from 'react';
import { GlobalToastRegion } from '@pledge-earth/product-language';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

import { FallbackPage } from '../pages/public/shared/ErrorPage/ErrorPage';
import { ReduxSagaProvider } from '../store/provider';
import { reducer as settings } from '../store/settings/reducers';
import { Localization } from '../localization';
import { GoogleMapsAPIProvider } from '../components/Map/GoogleMapsAPIProvider';
import '../pages/public/shared/ErrorPage/ErrorPage.scss';

import { FlagProvider } from './FlagProvider';

const reducer = combineReducers({
  settings,
});

const store = configureStore({
  reducer,
});

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_PUBLIC_ENDPOINT,
  cache: new InMemoryCache(),
});

export function ProviderCalculator({ children }: { children: ReactNode }) {
  return (
    <ApolloProvider client={client}>
      <ReduxSagaProvider store={store}>
        <Localization>
          <Sentry.ErrorBoundary fallback={<FallbackPage />}>
            <FlagProvider isAnonymous={true}>
              <GoogleMapsAPIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY || ''}>
                {children}
                <GlobalToastRegion />
              </GoogleMapsAPIProvider>
            </FlagProvider>
          </Sentry.ErrorBoundary>
        </Localization>
      </ReduxSagaProvider>
    </ApolloProvider>
  );
}
